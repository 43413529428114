import React from "react";
import { connectPoweredBy, connectStateResults } from "react-instantsearch-dom";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { Badge, IconButton, SvgIcon, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlgolia } from "@fortawesome/free-brands-svg-icons";

const PoweredByComponent = ({ url, searchResults }) => {
  const hitCount =
    searchResults && searchResults.nbHits > 0 ? searchResults.nbHits : null;
  return (
    <Tooltip title="Powered by Algolia">
      <Badge
        badgeContent={hitCount}
        max={99}
        overlap="circle"
        color="secondary"
      >
        <IconButton
          component={OutboundLink}
          href={url}
          target="_blank"
          rel="noreferrer noopener"
          color="primary"
        >
          <SvgIcon>
            <FontAwesomeIcon icon={faAlgolia} />
          </SvgIcon>
        </IconButton>
      </Badge>
    </Tooltip>
  );
};

export const PoweredBy = connectStateResults(
  connectPoweredBy(PoweredByComponent)
);
