import React, { useEffect, useState } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import {
  CircularProgress,
  IconButton,
  InputBase,
  Paper,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import styled from "styled-components";
import { PoweredBy } from "./powered-by";

const DEBOUNCE_DELAY = 300;

const StyledInputBase = styled(InputBase)`
  padding-left: 0.5rem;
  flex: 1;
`;

const StyledPaper = styled(Paper)`
  flex: 1 1 13rem;
  padding: 0.1rem;
  display: flex;
  align-items: center;
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const SearchBoxComponent = ({
  currentRefinement,
  isSearchStalled,
  refine,
  setRefine,
}) => {
  const [value, setValue] = useState(currentRefinement);

  useEffect(() => {
    setRefine(() => setValue);
    return () => {
      setRefine(null);
    };
  }, [setRefine]);

  useEffect(() => {
    const timeoutId = setTimeout(refine, DEBOUNCE_DELAY, value);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, refine]);

  const handleOnChange = ({ currentTarget }) => setValue(currentTarget.value);

  return (
    <SearchContainer>
      <div style={{ flex: 1 }} />
      <StyledPaper
        component="form"
        onSubmit={(e) => e.preventDefault()}
        role="search"
      >
        <StyledInputBase
          type="search"
          placeholder="Search"
          value={value}
          onChange={handleOnChange}
        />
        <IconButton aria-label="Search" type="submit">
          <SearchIcon />
          {isSearchStalled ? (
            <StyledCircularProgress size={48} thickness={1.6} />
          ) : null}
        </IconButton>
      </StyledPaper>
      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <PoweredBy />
      </div>
    </SearchContainer>
  );
};

export const SearchBox = connectSearchBox(SearchBoxComponent);
