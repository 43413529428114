import { InstantSearch, Configure } from "react-instantsearch-dom";
import React, { useState } from "react";
import qs from "qs";
import algoliasearch from "algoliasearch/lite";
import { SearchBox } from "./search-box";
import { Hits } from "./hits";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const createURL = (state) => `?${qs.stringify(state)}`;

const searchStateToUrl = (searchState) =>
  searchState
    ? `${
        typeof window !== "undefined" ? window.location.pathname : ""
      }${createURL(searchState)}`
    : "";

const urlToSearchState = () =>
  typeof window !== "undefined"
    ? qs.parse(window.location.search.slice(1))
    : {};

export const Search = () => {
  const [searchState, setSearchState] = useState(urlToSearchState());
  const [refine, setRefine] = useState(null);

  const onSearchStateChange = (updatedSearchState) => {
    if (typeof window === "undefined") return;

    const { query: search_term } = updatedSearchState;

    if (typeof window.gtag === "function") {
      window.gtag("event", "search", { search_term });
    }

    const url = searchStateToUrl(updatedSearchState);
    window.history.pushState(updatedSearchState, null, url);
    setSearchState(updatedSearchState);
  };
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <Configure attributesToSnippet={["excerpt"]} />
      <SearchBox setRefine={setRefine} />
      {/*
      <RefinementList attribute="tags" operator="and" />
*/}
      <Hits refine={refine} />
    </InstantSearch>
  );
};
