import React, { Fragment } from "react";
import styled from "styled-components";
import { connectHits, Highlight, Snippet } from "react-instantsearch-dom";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Link } from "gatsby";
import CodeLogos from "../code-logos";

const StyledListItemText = styled(ListItemText)`
  mark {
    background: transparent;
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

const Hit = (props) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <ListItem button component={Link} to={`/posts/${props.pathname}`}>
      <StyledListItemText
        primary={<Highlight attribute="title" hit={props} tagName="mark" />}
        secondary={
          <>
            <Highlight attribute="summary" hit={props} tagName="mark" />
            <br />
            <Typography variant="caption">
              <Snippet attribute="excerpt" hit={props} tagName="mark" />…
            </Typography>
          </>
        }
      />
    </ListItem>
    <CodeLogos
      tags={props.tags}
      size={5}
      refine={props.refine}
      flexFlow="inherit"
    />
  </div>
);

const HitsComponent = ({ hits, refine }) => (
  <List>
    {hits.map(({ objectID: key, ...props }) => (
      <Fragment key={key}>
        <Hit refine={refine} {...props} />
        <Divider />
      </Fragment>
    ))}
  </List>
);

export const Hits = connectHits(HitsComponent);
