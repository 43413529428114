import React from "react";
import Layout from "../../components/layout";
import { Search } from "../../components/search";

const PostsPage = () => {
  return (
    <Layout title="Posts">
      <Search />
    </Layout>
  );
};

export default PostsPage;
